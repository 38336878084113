import React from 'react'
import cn from 'classnames'
import { SwiperSlide } from 'swiper/react'
import Link from 'next/link'
import { Swiper } from '@features/home/components/CustomSwiper'
import { StoryCard } from '@components/StoryCard'
import StoryCardSkeleton from '@components/StoryCardSkeleton'
import { Right2Icon } from '@components/icons/Right2Icon'
import { TimeIcon } from '@components/icons/TimeIcon'
import { LatestUpdateBookProps } from './interface'

export const LatestUpdateBook = React.memo(
  ({
    className = '',
    direction,
    format,
    datas,
    categoryId,
    bookType,
    isEbook,
    isLoading,
    isShowNavigation,
    is1Row,
    showStoryDrawer,
  }: LatestUpdateBookProps) => {
    if (!isLoading && datas.length === 0) return null

    return (
      <section className={className}>
        <div
          className={cn('w-full flex flex-col', {
            'min-h-[572px] mobile:min-h-[868px] tablet:min-h-[505px]':
              isLoading,
          })}
        >
          <div className='font-mitr flex justify-between items-center mobile:mx-[21px] mb-[20px] mobile:mb-[10px]'>
            <div className='flex'>
              <TimeIcon className='text-primary w-[38px] h-[38px] mr-[10px] mobile:w-[24px] mobile:h-[24px]' />
              <h2 className='topic-base mobile:topic-small'>อัปเดตล่าสุด</h2>
            </div>
            <Link href='/latest-update' legacyBehavior prefetch={false}>
              <a className='flex items-center text-[14px] text-secondary cursor-pointer font-medium'>
                ดูทั้งหมด&nbsp;
                <Right2Icon width='14' height='14' />
              </a>
            </Link>
          </div>
          {isLoading ? (
            <div
              className={cn('grid gap-[20px] mx-auto mobile:max-w-[450px]', {
                'grid-cols-2 grid-rows-2 tablet:grid-cols-4 tablet:grid-rows-1':
                  direction === 'horizontal',
                'grid-cols-4 grid-rows-1 mobile:grid-cols-2 tablet:grid-cols-4 tablet:grid-rows-1':
                  direction === 'vertical',
              })}
            >
              {[...Array(is1Row ? 2 : 4)].map((l, i) => (
                <StoryCardSkeleton key={i} direction={direction} />
              ))}
            </div>
          ) : datas ? (
            <Swiper
              slidesPerView={1}
              loop
              isShowNavigation={isShowNavigation}
              centeredSlides
              className='recommended-swiper'
              containerClass='pb-[28px]'
              prevclass={cn('left-[-45px] tablet-mobile:hidden', {
                'top-[46%]': direction === 'horizontal' && !is1Row,
                'top-[42%]': direction === 'horizontal' && is1Row,
                'top-[46.6%]': direction === 'vertical',
              })}
              nextclass={cn('right-[-45px] tablet-mobile:hidden', {
                'top-[46%]': direction === 'horizontal' && !is1Row,
                'top-[42%]': direction === 'horizontal' && is1Row,
                'top-[46.6%]': direction === 'vertical',
              })}
              count={Math.ceil(datas.length / format.col)}
              spaceBetween={20}
            >
              {Array.from(
                { length: Math.ceil(datas.length / format.col) },
                (v, i) => i
              ).map((item: number) => {
                return (
                  <SwiperSlide key={item}>
                    <div
                      className={cn(
                        'mobile:max-w-[450px] w-full mobile:mx-[20px] grid gap-[20px] mb-[20px] mobile:gap-[10px]',
                        {
                          'grid-cols-2 grid-rows-2 tablet:grid-cols-4 tablet:grid-rows-1':
                            direction === 'horizontal' && !is1Row,
                          'grid-cols-2 grid-rows-1 tablet:grid-cols-2 tablet:grid-rows-1':
                            direction === 'horizontal' && is1Row,
                          'grid-cols-4 grid-rows-1 mobile:grid-cols-2 tablet:grid-cols-4 tablet:grid-rows-1':
                            direction === 'vertical',
                        }
                      )}
                    >
                      {format.grid.map((col: number) => {
                        const index = item * format.col + col
                        if (index < datas.length) {
                          return (
                            <StoryCard
                              key={index}
                              id={datas[index].id}
                              title={datas[index].title}
                              imageUrl={
                                datas[index].coverResizeImgPath ||
                                datas[index].coverImgPath
                              }
                              penName={datas[index].penName?.firstPenName}
                              slug={datas[index].penName?.user?.profilePageSlug}
                              content={datas[index].cover}
                              category={datas[index].category.name}
                              tags={datas[index].tags}
                              chapter={datas[index].chapterCount}
                              view={datas[index].viewCount}
                              chat={datas[index].commentCount}
                              like={datas[index].likeCount}
                              isLiked={datas[index].isLiked}
                              isEnded={datas[index].isEnded}
                              direction={direction}
                              bookType={datas[index].bookType}
                              categoryId={datas[index].category.id}
                              handleClickStoryCard={() =>
                                showStoryDrawer(datas[index])
                              }
                            />
                          )
                        }
                        return null
                      })}
                    </div>
                  </SwiperSlide>
                )
              })}
            </Swiper>
          ) : null}
        </div>
      </section>
    )
  }
)
