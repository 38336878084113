import Link from 'next/link'
import Image from 'next/image'
import { SwiperSlide } from 'swiper/react'
import { useInfiniteQuery } from 'react-query'

import { Swiper } from '@features/home/components/CustomSwiper'
import { BookBannerType } from '@models/banner/BookBannerType'
import { useWindowSize } from '@hooks/useWindowSize'
import { PenIcon } from '@components/icons/PenIcon'
import { Right2Icon } from '@components/icons/Right2Icon'
import { useBannerAction } from '@hooks/banner/useBannerAction'
import { BookBannersByHighlightGroupIdResponse } from '@models/banner/BookBannersByHighlightGroupIdResponse'

export function HighlightBookBanner({
  id,
  bannerName,
  banners,
}: {
  id: number
  bannerName: string
  banners: BookBannerType[]
}) {
  const { isDesktop } = useWindowSize()
  const bannerAction = useBannerAction()

  const { fetchNextPage, data, isFetchingNextPage } = useInfiniteQuery(
    ['book-banner', id],
    async ({ pageParam = 1 }) => {
      const res = await bannerAction.getBookBannersByHighlightGroupId({
        highlightGroupId: id,
        page: pageParam,
      })

      return res
    },
    {
      getNextPageParam: (lastPage: BookBannersByHighlightGroupIdResponse) => {
        if (lastPage.data.length) return lastPage.page + 1

        return undefined
      },
      initialData: {
        pages: [{ data: banners, page: 1 }],
        pageParams: [null],
      },
      enabled: false,
    }
  )

  function handleFetchNextPage(value: number) {
    if (data && !isFetchingNextPage && value > (data.pages.length - 1) * 10) {
      fetchNextPage()
    }
  }

  return (
    <div className='flex flex-col justify-center w-full  h-fit max-w-[1160px] mx-auto'>
      <div className='grid grid-cols-[1fr_auto] gap-x-[20px] tablet-mobile:mx-[20px] '>
        <h2 className='topic-base mobile:topic-small font-mitr text-left truncate'>
          {bannerName}
        </h2>
        <Link href={`/campaign/${id}`} legacyBehavior prefetch={false}>
          <a className='flex items-center font-mitr font-medium text-[14px] text-secondary cursor-pointer whitespace-nowrap'>
            ดูทั้งหมด&nbsp;
            <Right2Icon width='14' height='14' />
          </a>
        </Link>
      </div>
      {!!data?.pages.length && (
        <Swiper
          className='mt-[20px]'
          slidesPerView={isDesktop ? 7 : 'auto'}
          spaceBetween={15}
          isShowNavigation
          prevclass='left-[-48px] tablet:left-[20px]'
          nextclass='right-[-48px] tablet:right-[20px]'
          handleOnSlideChange={handleFetchNextPage}
        >
          {data.pages.map(page =>
            page.data.map(item => {
              return (
                <SwiperSlide
                  className='tablet-mobile:first:ml-[15px] tablet-mobile:last:mr-[15px]'
                  key={item.id}
                >
                  <Link
                    href={
                      item.isEbook
                        ? `/story/${item.bookId}/ebook`
                        : `/story/${item.bookId}`
                    }
                    legacyBehavior
                    prefetch={false}
                  >
                    <a>
                      <div className='relative w-[153px] h-[215.16px] cursor-pointer rounded-[8px] overflow-hidden bg-gray-3'>
                        {item.book?.coverResizeImgPath ||
                        item.book?.coverImgPath ? (
                          <Image
                            src={
                              item.book?.coverResizeImgPath ||
                              item.book?.coverImgPath
                            }
                            alt={item.book.title}
                            layout='fill'
                            sizes='(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw'
                            quality={15}
                          />
                        ) : null}
                      </div>
                      <div className='w-[145px]'>
                        <p className='font-mitr font-meduim text-[14px] text-secondary mt-[10px] truncate'>
                          {item.book?.title}
                        </p>
                        {item.book?.isTranslated ? (
                          <p className='flex space-x-[5px] text-[12px] text-secondary-1 mt-[6px] font-light'>
                            <PenIcon className='w-[18px] h-[18px]' />
                            <span className='text-secondary w-[100px] line-clamp-1 break-all'>
                              {item.book?.writer}
                            </span>
                          </p>
                        ) : (
                          <p className='flex space-x-[5px] text-[12px] text-secondary-1 mt-[6px] font-light'>
                            <PenIcon className='w-[18px] h-[18px]' />
                            <span className='text-secondary w-[80px] line-clamp-1 break-all'>
                              {item.book?.penName?.firstPenName}
                            </span>
                          </p>
                        )}
                      </div>
                    </a>
                  </Link>
                </SwiperSlide>
              )
            })
          )}
        </Swiper>
      )}
    </div>
  )
}
