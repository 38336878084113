/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-shadow */
import React from 'react'
import Image from 'next/image'
import Link from 'next/link'
import cn from 'classnames'
import { SwiperSlide } from 'swiper/react'

import { Swiper } from '@features/home/components/CustomSwiper'
import { MainBannerType } from '@models/banner/MainBannerType'
import { BannerSectionProps } from './interface'

function customPlugin({ swiper, on }: any) {
  on('slideChange', () => {
    if (window.innerWidth < 1280) return
    swiper.slides.forEach((elem: any, index: number) => {
      if (index < swiper.activeIndex) {
        elem.style.transform = 'translateX(-30%)'
      } else if (index > swiper.activeIndex) {
        elem.style.transform = 'translateX(30%)'
      } else {
        elem.style.transform = 'scale(1.6)'
      }
    })
  })
}

export const BannerSection = React.memo(
  ({ mainBanners }: BannerSectionProps) => {
    return (
      <section>
        {/* <div className='hidden desktop:block h-[388px]'>
          <Image
            src='/images/banner-section-bg.webp'
            alt='banner-bg'
            className='z-0'
            layout='responsive'
            width={1920}
            height={388}
            quality={15} // Increase quality for better visual experience
            priority
          />
        </div> */}

        <div className='desktop:h-[388px] bg-banner-section bg-no-repeat bg-[length:100%_388px] mobile:bg-cover bg-top'>
          {/* <div className='desktop:h-[388px]'> */}

          {!!mainBanners?.length && (
            <Swiper
              modules={[customPlugin]}
              slidesPerView='auto'
              spaceBetween={20}
              autoplay={{ delay: 5000, disableOnInteraction: false }}
              centeredSlides
              isShowNavigation
              loop
              count={mainBanners.length}
              IconClass='text-white'
              activeBulletClassname='bg-white'
              bulletClassname='bg-white'
              className='desktop:!h-[360px]'
              paginationClassName='my-0 absolute desktop:bottom-[30px] left-1/2 -translate-x-1/2  bottom-[4px]  '
            >
              {mainBanners
                .filter(item => item.web)
                .map((item: MainBannerType, index: number) => {
                  return (
                    <SwiperSlide key={item.id} className='active-main-banner'>
                      {() => (
                        <Link key={item.id} href={item.link} passHref>
                          <a rel='noreferrer' target='_blank'>
                            <div
                              className={cn(
                                'relative cursor-pointer desktop:w-[466.7px] tablet-mobile:w-[100vw] pt-[42.86%]'
                              )}
                            >
                              <Image
                                className='desktop:rounded-[8px]'
                                src={item.coverImgPath}
                                layout='fill'
                                alt={item.description}
                                quality={25} // Adjust quality for better balance between performance and visual experience
                                sizes='(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw'
                              />
                            </div>
                          </a>
                        </Link>
                      )}
                    </SwiperSlide>
                  )
                })}
            </Swiper>
          )}
        </div>
      </section>
    )
  }
)
