import React from 'react'
import cn from 'classnames'
import { SwiperSlide } from 'swiper/react'

import { Container } from '@components/Container'
import { Swiper } from '@features/home/components/CustomSwiper'
import { TopRankFilter } from '@components/TopRankFilter'
import { TopRankCard } from '@components/TopRankCard/TopRankCard'
import NoData from '@components/NoData'
import { rankNumbers } from '@constants/rankNumbers'
import { BookTopChartProps } from './interface'

export const BookTopChart = React.memo(
  ({
    query,
    books,
    isLoading,
    dateOfWeek,
    title,
    disableNext,
    disablePrev,
    className,
    disabledClassName,
    type,
    isDesktop,
    isMobile,
    handleCategoryChange,
    handleBookTypeChange,
    nextWeek,
    prevWeek,
    handleClickTopChart,
  }: BookTopChartProps) => {
    return (
      <div className={className}>
        <Container className='!py-0'>
          <TopRankFilter
            isSeeAll
            dateOfWeek={dateOfWeek}
            title={title}
            category={query.categoryId}
            storyType={query.bookType}
            disableNext={disableNext}
            disablePrev={disablePrev}
            handleChangeCategory={handleCategoryChange}
            handleChangeStoryType={handleBookTypeChange}
            handleClickNextWeek={nextWeek}
            handleClickPrevWeek={prevWeek}
            disabledClassName={disabledClassName}
            type={type}
            isHomePage
          />
          <div>
            {isLoading ? (
              <div className='flex overflow-hidden'>
                {Array.from(Array(3)).map((_, i) => (
                  <div
                    key={i}
                    className={cn(
                      'desktop-tablet:w-[500px] w-[294px] cursor-pointer p-[15px] rounded-[8px] flex desktop-tablet:items-center desktop-tablet:h-[180px] h-[120px]'
                    )}
                  >
                    <div className='bg-gray-3 w-[80px] mx-[10px] rounded-[8px] h-full' />
                    <div className='desktop-tablet:max-w-[400px] animate-pulse desktop-tablet:w-[400px] desktop-tablet:h-[152px] w-[240px] h-[115px]  rounded-[8px] shadow border-[0.5px] border-gray-3 flex '>
                      <div className='desktop-tablet:min-w-[106px] desktop-tablet:w-[106px] w-[77px] desktop-tablet:h-[152px] h-[115px] bg-gray-3  rounded-[8px] ' />
                      <div className='grid p-[20px] gap-y-[20px]'>
                        <div className='h-[20px] w-[120px] bg-gray-3 rounded-[8px]' />
                        <div className='h-[20px] w-[200px] bg-gray-3 rounded-[8px]' />
                        <div className='h-[20px] w-[180px] bg-gray-3 rounded-[8px]' />
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            ) : books && books.length > 0 ? (
              <Swiper
                slidesPerView={
                  isDesktop && books.length >= 3
                    ? 'auto'
                    : !isDesktop && books.length >= 2
                    ? 'auto'
                    : books.length
                }
                spaceBetween={isMobile ? 20 : 40}
                loop
                isShowNavigation
                prevclass='left-[-45px] tablet:left-[5px] mobile:hidden'
                nextclass='right-[-45px] tablet:right-[5px] mobile:hidden'
                className={cn({
                  'swiper-content-first': books.length === 1,
                  'swiper-content-second': books.length === 2,
                })}
              >
                {books.map((item, index) => (
                  <SwiperSlide className='p-[20px_0]' key={item.id}>
                    <div
                      className={cn(
                        'cursor-pointer flex desktop-tablet:items-center',
                        {
                          'cursor-not-allowed':
                            item.book.isBanned || item.book.deletedAt,
                        }
                      )}
                      onClick={() => handleClickTopChart(item)}
                    >
                      <div className='text-primary absolute'>
                        {rankNumbers[index]}
                      </div>
                      <TopRankCard
                        className={cn(
                          'relative ml-[50px] desktop-tablet:ml-[64px] desktop-tablet:w-[400px] desktop-tablet:min-w-[400px] w-[298px]',
                          { '!ml-[100px] mobile:!ml-[60px]': index === 9 }
                        )}
                        key={item.id}
                        bookDetail={item}
                        slug={item.book?.penName?.user?.profilePageSlug}
                      />
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            ) : (
              <NoData />
            )}
          </div>
        </Container>
      </div>
    )
  }
)
